const REQUESTED_TITLE_REPORT_STATE = "REQUESTED_TITLE_REPORT_STATE";
const REQUESTED_TITLE_REPORT_LINK = "journal-article-title-requested-report";

const FOUND_NOT_FOUND_DETAILS_REPORT_STATE =
    "FOUND_NOT_FOUND_DETAILS_REPORT_STATE";
const FOUND_NOT_FOUND_DETAILS_REPORT_LINK =
    "article-found-not-found-details-report";

const PAYMENT_DETAILS_REPORT_STATE = "PAYMENT_DETAILS_REPORT_STATE";
const PAYMENT_DETAILS_REPORT_LINK = "payment-information-details-report";

const SCIENTIST_DETAILS_REPORT_STATE = "SCIENTIST_DETAILS_REPORT_STATE";
const SCIENTIST_DETAILS_REPORT_LINK = "scientist-details-report";

const RESEARCH_DETAILS_REPORT_STATE = "RESEARCH_DETAILS_REPORT_STATE";
const RESEARCH_DETAILS_REPORT_LINK = "research-details-report";

const PUBLICATION_DETAILS_REPORT_STATE = "PUBLICATION_DETAILS_REPORT_STATE";
const PUBLICATION_DETAILS_REPORT_LINK = "publication-details-report";

export {
    REQUESTED_TITLE_REPORT_STATE,
    REQUESTED_TITLE_REPORT_LINK,
    FOUND_NOT_FOUND_DETAILS_REPORT_STATE,
    FOUND_NOT_FOUND_DETAILS_REPORT_LINK,
    PAYMENT_DETAILS_REPORT_STATE,
    PAYMENT_DETAILS_REPORT_LINK,
    SCIENTIST_DETAILS_REPORT_STATE,
    SCIENTIST_DETAILS_REPORT_LINK,
    RESEARCH_DETAILS_REPORT_STATE,
    RESEARCH_DETAILS_REPORT_LINK,
    PUBLICATION_DETAILS_REPORT_STATE,
    PUBLICATION_DETAILS_REPORT_LINK,
};
