import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/report`
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getNSTFellowshipApplicantReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-applicant-report`,
    getNSTFellowshipPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-preliminary-selected-applicant-report`,
    getNSTFellowshipMarkDistributionReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-mark-distribution-report`,
    getBSTFApplication: () => `${RESOURCE_ENDPOINT}/bstf-application-report`,
    getBSTFApplicant: () => `${RESOURCE_ENDPOINT}/bstf-applicant-report`,
    getRandDProjectMoSTApplication: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-application-report`,
    getRandDProjectMoSTApplicant: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-applicant-report`,
    getPeerReviewCommitteeMember: () => `${RESOURCE_ENDPOINT}/peer-review-committee-member-report`,
    getScientificORGApplicationReport: () => `${RESOURCE_ENDPOINT}/scientific-org-application-report`,
    getScientificORGApplicantReport: () => `${RESOURCE_ENDPOINT}/scientific-org-application-report`,
}

export default class ReportGrantApi {
    public getExampleReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipMarkDistributionReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipMarkDistributionReport();
        return HttpService.get(url, params, headers);
    }
    public getRandDProjectMoSTApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }
    public getRandDProjectMoSTApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }
    public getPeerReviewCommitteeMember = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPeerReviewCommitteeMember();
        return HttpService.get(url, params, headers);
    }
    public getScientificORGApplicationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientificORGApplicationReport();
        return HttpService.get(url, params, headers);
    }
    public getScientificORGApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientificORGApplicantReport();
        return HttpService.get(url, params, headers);
    }

    public getBSTFApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplication();
        return HttpService.get(url, params, headers);
    }

    public getBSTFApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplicant();
        return HttpService.get(url, params, headers);
    }
}