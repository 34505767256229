import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/export`
const endpoints = {
    exportJournalApplicationReport: () => `${RESOURCE_ENDPOINT}/journal-application-report-export`,
    exportJournalArticleTitleRequestedReport: () => `${RESOURCE_ENDPOINT}/journal-article-title-requested-report-export`,
    exportPaymentInformationReport: () => `${RESOURCE_ENDPOINT}/payment-information-report-export`,
    exportPaymentInformationDetailsReport: () => `${RESOURCE_ENDPOINT}/payment-information-details-report-export`,
    exportFoundNotFoundReport: () => `${RESOURCE_ENDPOINT}/article-found-not-found-report-export`,
    exportFoundNotFoundDetailsReport: () => `${RESOURCE_ENDPOINT}/article-found-not-found-details-report-export`,
    exportResearchFieldWiseRequestReport: () => `${RESOURCE_ENDPOINT}/research-field-wise-request-report-export`,
    exportScientistInformationReport: () => `${RESOURCE_ENDPOINT}/scientist-details-report-export`,
    exportDisciplineSectorWiseReport: () => `${RESOURCE_ENDPOINT}/discipline-sector-wise-scientist-report-export`,
    exportScientistProfileReport: () => `${RESOURCE_ENDPOINT}/scientist-profile-report-export`,
    exportResearchDetailsReport: () => `${RESOURCE_ENDPOINT}/research-details-report-export`,
    exportPublicationDetailsReport: () => `${RESOURCE_ENDPOINT}/publication-details-report-export`,
}

export default class ExportResearchApi {
    
    public exportJournalApplicationReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportJournalApplicationReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportJournalArticleTitleRequestedReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportJournalArticleTitleRequestedReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportPaymentInformationReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportPaymentInformationReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportPaymentInformationDetailsReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportPaymentInformationDetailsReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportFoundNotFoundReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportFoundNotFoundReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportFoundNotFoundDetailsReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportFoundNotFoundDetailsReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportResearchFieldWiseRequestReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportResearchFieldWiseRequestReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportScientistInformationReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportScientistInformationReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportDisciplineSectorWiseReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDisciplineSectorWiseReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportScientistProfileReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportScientistProfileReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportResearchDetailsReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportResearchDetailsReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportPublicationDetailsReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportPublicationDetailsReport();
        return HttpService.post(url, payload, params, headers);
    }

}

