import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/doptor-service`
const endpoints = {
    getOfficeList: () => `${RESOURCE_ENDPOINT}/office-list`,
    getOfficeUnitList: () => `${RESOURCE_ENDPOINT}/office-unit-list`,
    getOfficeUnitOrganogramList: () => `${RESOURCE_ENDPOINT}/office-unit-organogram-list`,
    getFontDeskList: () => `${RESOURCE_ENDPOINT}/font-desk`,
}

export default class DoptorServiceApi {
    public getOfficeList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOfficeList();
        return HttpService.post(url, payload, params, headers);
    }

    public getOfficeUnitList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOfficeUnitList();
        return HttpService.post(url, payload, params, headers);
    }

    public getOfficeUnitOrganogramList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOfficeUnitOrganogramList();
        return HttpService.post(url, payload, params, headers);
    }

    public getFontDeskList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getFontDeskList();
        return HttpService.post(url, payload, params, headers);
    }
}