import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/webinar-archive`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    // getSessionTimeInformation: (webinarEventId: any) => `${RESOURCE_ENDPOINT}/get-session-time-information/${webinarEventId}`,
    // getReporterInformation: (webinarEventId: any) => `${RESOURCE_ENDPOINT}/get-reporter-information/${webinarEventId}`,
    // getReputearInformation: (webinarEventId: any) => `${RESOURCE_ENDPOINT}/get-reputear-information/${webinarEventId}`,
    getByApplicationId: (applicationId: any) =>
        `${RESOURCE_ENDPOINT}/application/${applicationId}`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
};

export default class WebinarArchiveApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();

        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    // public getSessionTimeInformation = (
    //     webinarEventId: any
    // ): AxiosPromise<any> => {
    //     const url = endpoints.getSessionTimeInformation(webinarEventId);
    //     return HttpService.get(url);
    // };

    // public getReporterInformation = (
    //     webinarEventId: any
    // ): AxiosPromise<any> => {
    //     const url = endpoints.getReporterInformation(webinarEventId);
    //     return HttpService.get(url);
    // };
    // public getReputearInformation = (
    //     webinarEventId: any
    // ): AxiosPromise<any> => {
    //     const url = endpoints.getReputearInformation(webinarEventId);
    //     return HttpService.get(url);
    // };

    public getByApplicationId = (applicationId: any): AxiosPromise<any> => {
        const url = endpoints.getByApplicationId(applicationId);
        return HttpService.get(url);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        console.log(payload);
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };
    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };
}
