export const dateFormat = "YYYY-MM-DD";
export const DATE_FORMAT_DATABASE = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_DATABASE = "YYYY-MM-DD HH:mm:ss";
export const YEAR_FORMAT_DATABASE = "YYYY";
export const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const DATE_PICKER_DATE_FORMAT = "DD-MMM-YYYY";
export const DATE_PICKER_DATE_FORMAT_A = "DD-MM-YYYY";
export const DATE_PICKER_DATE_TIME_FORMAT = "DD-MMM-YYYY h:mm a";
export const DATE_PICKER_DATE_TIME_FORMAT_AM_PM = "MMM DD, YYYY h:mm A";
export const DATE_PICKER_MONTH_FORMAT = "YYYY-MM";
export const DATE_PICKER_MONTH_NAME_FORMAT = "MMMM YYYY";
export const DATE_PICKER_TIME_FORMAT = "HH:mm:ss";
export const DATE_PICKER_YEAR_FORMAT = "YYYY";
export const DATE_PICKER_TIME_FORMAT_WITH_AM_PM = "HH:mm A";
export const DATE_PICKER_DATE_TIME_FORMAT_B = "YYYY-MM-DD h:mm:s A";
export const DATE_PICKER_DATE_TIME_FORMAT_ZOOM = "DD-MM-YYYY h:mm:s A";
export const DATE_PICKER_DATE_MONTH_YEAR_FORMAT = "DD MMMM YYYY";
export const HUMAN_DATETIME_FORMAT = "DD MMM YYYY hh:mm A"; // 16 Dec 1971.

// Timers
export const INTERVAL_ONE_SECOND = 1000;
export const INTERVAL_TEN_SECONDS = 10 * 1000;
export const INTERVAL_THIRTY_SECONDS = 30 * 1000;

export const INTERVAL_ONE_MINUTE = 60 * 1000;
export const INTERVAL_TWO_MINUTES = 2 * 60 * 1000;
export const INTERVAL_FIVE_MINUTES = 5 * 60 * 1000;

export const INTERVAL_ONE_HOUR = 60 * 60 * 1000;
export const INTERVAL_ONE_DAY = 24 * 60 * 60 * 1000;

// Token Refresh
export const TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000;
