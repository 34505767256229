import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.UTIL_SERVER_PREFIX}/zoom`;
const endpoints = {
    getMeetingSummary: () => `${RESOURCE_ENDPOINT}/meeting-summary`,
};

export default class ZoomApi {
    public getMeetingSummary = (payload): AxiosPromise<any> => {
        const url = endpoints.getMeetingSummary();
        return HttpService.post(url, payload);
    };
}
