import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/researcher-profile`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    findScientist: () => `${RESOURCE_ENDPOINT}/find-scientist`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getProfileInfoById: (id: any) => `${RESOURCE_ENDPOINT}/get-profile/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    getTopAuthor: (limit: any) => `${RESOURCE_ENDPOINT}/top-author/${limit}`,
    getTopPublisher: (limit: any) =>
        `${RESOURCE_ENDPOINT}/top-publisher/${limit}`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
};

export default class ResearchProfileApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public findScientist = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.findScientist();
        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getProfileInfoById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getProfileInfoById(id);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    };

    // Get Top 10 Authors for Journal Article Filter
    public getTopAuthor = (limit: any): AxiosPromise<any> => {
        const url = endpoints.getTopAuthor(limit);
        return HttpService.get(url);
    };

    // Get Top 10 Publisher Name for Journal Article Filter
    public getTopPublisher = (limit: any): AxiosPromise<any> => {
        const url = endpoints.getTopPublisher(limit);
        return HttpService.get(url);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };

    public importGrantScientistProfile = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/researcher-profile-import`;
        return HttpService.post(url, payload, params, headers);
    };
}
