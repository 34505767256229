import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/webinar-newsletter`;
const endpoints = {
  list: () => `${RESOURCE_ENDPOINT}`,
  getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
  getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
  getByApplicationId: (applicationId: any) => `${RESOURCE_ENDPOINT}/application/${applicationId}`,
  create: () => `${RESOURCE_ENDPOINT}`,
  createExistingAudience: () => `${RESOURCE_ENDPOINT}/save-existing-audience`,
  update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  verifyAndUpdateSubscriber: (uuid: Number) => `${RESOURCE_ENDPOINT}/verify-subscriber/${uuid}`,
  updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
  dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
  newsletterGroupDropdown: () => `${RESOURCE_ENDPOINT}/newsletterGroupDropdown`,
  newsletterSourceDropdown: () => `${RESOURCE_ENDPOINT}/newsletterSourceDropdown`,
}

export default class WebinarNewsletterApi {
  public list = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.list();
    return HttpService.get(url, params, headers);
  }

  public getById = (id: any): AxiosPromise<any> => {
    const url = endpoints.getById(id);
    return HttpService.get(url);
  }

  public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getByWhere();
    return HttpService.get(url, params, headers);
  }

  public getByApplicationId = (applicationId: any): AxiosPromise<any> => {
    const url = endpoints.getByApplicationId(applicationId);
    return HttpService.get(url);
  };

  public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.create();
    return HttpService.post(url, payload, params, headers);
  }
  public createExistingAudience = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.createExistingAudience();
    return HttpService.post(url, payload, params, headers);
  }

  public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.update(id);
    return HttpService.put(url, payload, params, headers);
  }
  public verifyAndUpdateSubscriber = (uuid: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.verifyAndUpdateSubscriber(uuid);
    return HttpService.put(url, payload, params, headers);
  }

  public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.updatePartial(id);
    return HttpService.patch(url, payload, params, headers);
  }

  public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.delete(id);
    return HttpService.delete(url, params, headers);
  }

  public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.bulk();
    return HttpService.post(url, payload, params, headers);
  }

  public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.dropdown();
    return HttpService.get(url, params, headers);
  } 
  public newsletterGroupDropdown = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.newsletterGroupDropdown();
    return HttpService.get(url, params, headers);
  }

public newsletterSourceDropdown = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.newsletterSourceDropdown();
    return HttpService.get(url, params, headers);
  }

}
