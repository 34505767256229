import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/research-dashboard`;
const endpoints = {
    getResearchWiseDataCount: () => `${RESOURCE_ENDPOINT}/research-wise-data`,
}

export default class AdminDashboardApi {

  public getResearchWiseDataCount = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getResearchWiseDataCount();
    return HttpService.get(url, params, headers);
  }


}
