const initialState = {
  loading: true,
  isLoaded: false,
  isAuthenticated: null,
  isAdmin: null,
  isUser: null,
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),

  user: null as any,
  userId: null,
  userName: null,
  
  profileId: null,
  profileNameEn: null,
  profileNameBn: null,
  profileType: null,
  profileImage: null,

  departmentId: null,
  designationId: null,

  scientistProfileId: null,
  scientistProfileInfo: null,

  groupIds: [] as any,
  groupNameList: [] as any,
  groupCodeList: [] as any,

  roleIds: [] as any,
  roleNameList: [] as any,
  roleCodeList: [] as any,

  organogramId: null,
  organogramIds: [] as any,
  organogramNameEn: null,
  organogramNameBn: null,

  organizationId: null,
  organizationIds: [] as any,
  organizationNameEn: null,
  organizationNameBn: null,

  scopes: [] as any,
  error: null,

  dispatchAuth: (action) => null,
  loadAuthState: (token?) => null,
  clearAuthState: () => null,
  setLoading: (isShow) => null,
};

export default initialState;
