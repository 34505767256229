import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/export`
const endpoints = {
    exportExample: () => `${RESOURCE_ENDPOINT}/example`,
    exportExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    exportNSTFellowshipApplicant: () => `${RESOURCE_ENDPOINT}/nst-fellowship-applicant-report-export`,
    exportNSTFellowshipPreliminarySelectedApplicant: () => `${RESOURCE_ENDPOINT}/nst-fellowship-preliminary-selected-applicant-report-export`,
    exportNSTFellowshipMarkDistributionApplicant: () => `${RESOURCE_ENDPOINT}/nst-fellowship-mark-distribution-report-export`,
    exportBSTFApplication: () => `${RESOURCE_ENDPOINT}/bstf-application-report`,
    exportBSTFApplicant: () => `${RESOURCE_ENDPOINT}/bstf-applicant-report`,
    exportRandDProjectMoSTApplication: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-application-report`,
    exportRandDProjectMoSTApplicant: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-applicant-report`,
    exportPeerReviewCommitteeMember: () => `${RESOURCE_ENDPOINT}/peer-review-committee-member-report`,
    exportScientificORGApplicationReport: () => `${RESOURCE_ENDPOINT}/scientific-org-application-report`,
    exportScientificORGApplicantReport: () => `${RESOURCE_ENDPOINT}/scientific-org-applicant-report`,
}

export default class ExportGrantApi {
    public exportExample = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportExample();
        return HttpService.post(url, payload, params, headers);
    }

    public exportExampleReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportExampleReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportNSTFellowshipApplicant = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportNSTFellowshipApplicant();
        return HttpService.post(url, payload, params, headers);
    }

    public exportNSTFellowshipPreliminarySelectedApplicant = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportNSTFellowshipPreliminarySelectedApplicant();
        return HttpService.post(url, payload, params, headers);
    }

    public exportNSTFellowshipMarkDistributionApplicant = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportNSTFellowshipMarkDistributionApplicant();
        return HttpService.post(url, payload, params, headers);
    }

    public exportRandDProjectMoSTApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }

    public exportBSTFApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBSTFApplication();
        return HttpService.get(url, params, headers);
    }
 
    public exportBSTFApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBSTFApplicant();
        return HttpService.get(url, params, headers);
    }

    public exportRandDProjectMoSTApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportRandDProjectMoSTApplicant();
        return HttpService.get(url, params, headers);
    }
    public exportPeerReviewCommitteeMember = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportPeerReviewCommitteeMember();
        return HttpService.get(url, params, headers);
    }
    public exportScientificORGApplicationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportScientificORGApplicationReport();
        return HttpService.get(url, params, headers);
    }
    public exportScientificORGApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportScientificORGApplicantReport();
        return HttpService.get(url, params, headers);
    }
}

